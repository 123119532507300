import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import ErpOrderLineItemEntity from "../../../order_line_item/entity";
import UtilsErp from "../../../../../utils/erp";
import UtilsEntity from "../../../../../utils/entity";

export default class ErpOrderGeneral {
    private parent: any;
    private entity = "erp/order_items";
    private toastr: any;
    private datatable: any = null;
    private datatableElem: any;
    private editModal: bootstrap.Modal;
    private data: any = {}
    private currentStockData: any = {}
    private currentProductUuid: any = null
    mode: string;

    constructor(parent: any, mode = "show") {
        this.mode = mode
        this.parent = parent
        this.toastr = parent.toastr
        this.currentStockData = {}
        this.datatableElem = jQuery(".datatables-erp-order-general")
        this.editModal = new bootstrap.Modal((document.querySelector("#editErpOrderLineItemModal") as HTMLElement));
        this.bindListeners();
    }

    async getEntityData(elem: any) {
        const d = [];
        let mapRows = Array.from(document.querySelectorAll("#erp_order_line_item_add_warehouse_list_table tbody tr"));
        mapRows = mapRows.filter((map => {
            //@ts-ignore
            const input = map.querySelector("input") as HTMLInputElement;
            const inputValue = parseInt(input.value)
            return inputValue > 0
        }));
        for (const map of mapRows) {
            //@ts-ignore
            const input = map.querySelector("input") as HTMLInputElement;
            const quantity = parseInt(input.value)
            const warehouseId = input.getAttribute("data-warehouse-id");
            const warehouseLocationId = input.getAttribute("data-warehouse-location-id");
            const warehousePalletId = input.getAttribute("data-warehouse-pallet-id");
            //@ts-ignore
            let selectedSerials = Array.from(input.closest(".row").querySelectorAll(".form-select option:checked")).map(s => s.value);
            d.push({
                serials: selectedSerials.length > 0 ? selectedSerials : Array(quantity).fill(""),
                warehouse_id: warehouseId,
                warehouse_pallet_id: warehousePalletId === null || warehousePalletId === "null" ? null : warehousePalletId,
                warehouse_location_id: warehouseLocationId,
                quantity: quantity,
            })
        }

        return d;
    }

    getEntityDataPallet(elem: any) {
        return {
            warehouseId: elem.querySelector("#erp_pallet_warehouse_id option:checked")?.value,
            warehousePalletId: elem.querySelector("#erp_pallet_warehouse_location_pallet_id option:checked")?.value,
        }
    }

    async submitLineItem() {
        const elem = document.querySelector("#addErpOrderLineItemForm") as HTMLFormElement;
        const valid = elem.checkValidity();
        if (valid) {
            await Utils.showLoader();
            const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddErpOrderLineItem") as HTMLElement))

            if (this.mode === "show") {
                const entityData = await this.getEntityData(elem)
                    console.log("entityData", entityData)
                    const r = await Utils.entity.upsert({items: entityData, order_id: this.parent.id, product_id: this.currentProductUuid, customer_id: this.parent.data.customer.uuid}, this.entity)
                    this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                    const table = document.querySelector("#erp_order_line_item_add_warehouse_list_table tbody") as HTMLElement;
                    table.innerHTML = "";
                    await this.parent.getEntity()
            } else {
                const entityData = await this.getEntityData(elem);
                console.log("entityData", entityData)
                await this.parent.addLineItem(entityData)
            }
            if (bsElem) {
                bsElem.hide();
            }
            jQuery("#new_erp_order_line_item_product_id").val("").trigger("change").html("");
            elem.reset();
            await Utils.hideLoader();
        }
    }

    async submitLinePallet() {
        const elem = document.querySelector("#addErpOrderWarehouseLocationPalletForm") as HTMLFormElement;
        const valid = elem.checkValidity();
        if (valid) {
            await Utils.showLoader();
            const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddErpOrderPallet") as HTMLElement))

            const entityData = this.getEntityDataPallet(elem);
            console.log("AAA", this.parent)
            await this.parent.addLinePallet(entityData)
            if(this.mode === "show") {
                await this.parent.getEntity()
            }
            this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
            await Utils.hideLoader();
            jQuery("#erp_pallet_warehouse_location_pallet_id").val("").trigger("change");
            if (bsElem) {
                bsElem.hide();
            }
        }
    }

    bindListeners() {
        (document.querySelector("#addErpOrderLineItemForm") as HTMLFormElement).addEventListener("submit", async (e: any) => {
            e.preventDefault();
            await this.submitLineItem()
        });
        (document.querySelector("#addErpOrderWarehouseLocationPalletForm") as HTMLFormElement).addEventListener("submit", async (e: any) => {
            e.preventDefault();
            await this.submitLinePallet()
        });

        (document.querySelector("#editErpOrderLineItemForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editErpOrderLineItemForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const entityData = await this.getEntityData(elem);
                if (entityData.quantity > this.data.quantity + this.currentStockData.stock) {
                    this.toastr.error(`Customer does not have ${entityData.quantity} available. Currently available: ${this.data.quantity + this.currentStockData.stock}`, `${Utils.translate('generic.failed')}`)
                } else {
                    const r = await Utils.entity.upsert(entityData, this.entity)
                    if (r.status === 200) {
                        await this.parent.getEntity()
                        const bsElem = bootstrap.Modal.getInstance((document.querySelector("#editErpOrderLineItemModal") as HTMLElement))
                        if (bsElem) {
                            bsElem.hide();
                        }
                        this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                    }
                }
            }
        });

        jQuery("#new_erp_order_line_item_product_id").on("select2:select", (e: any) => {
            this.currentProductUuid = e.params.data.data.uuid
        })

        this.datatableElem.delegate(".delete-record", "click", async (e: any) => {
            e.preventDefault();
            const ids = e.currentTarget.getAttribute("data-ids");
            const confirm = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if (confirm.value === true) {
                if (this.mode === "show") {
                    const r = await Utils.entity.destroyAll(ids.split(","), this.entity);
                    if (r.status === 200) {
                        this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                    } else {
                        this.toastr.error(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                    }
                    await this.parent.getEntity()
                } else {
                    this.parent.removeLineItem(ids)
                }
            }
        });

        /*(document.querySelector("#erp_order_line_item_tableall") as HTMLInputElement).addEventListener("click", (e) => {
            const target = e.target as HTMLInputElement;
            target.checked = !target.checked
            document.querySelectorAll("input[id^='erp_order_line_item_table_']").forEach((cb: any) => {
                cb.checked = target.checked
            })
        })*/
    }

    createDataTable() {
        this.datatable = new DataTable(".datatables-erp-order-general", {
            processing: true,
            layout: {
                topEnd: ['search', 'buttons']
            },
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddErpOrderLineItem'
                    }
                },
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')} ${Utils.translate('erp.warehouse_location_pallet.name')}</span>`,
                    className: 'dt-button add-new btn btn-primary',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddErpOrderPallet'
                    }
                }
            ]
        });
    }

    async getSubentities() {
        const r = await UtilsEntity.getAll("product_attributes");
        if (r.status === 200) {
            return r.data;
        } else {
            return {}
        }
    }

    async update(data: any, enableActions = true) {
        if (this.datatable) {
            this.datatable.destroy();
        }
        await Utils.updateElements(data, '', (document.querySelector("#erp_order_general") as HTMLElement))
        const table = document.querySelector(".datatables-erp-order-general tbody") as HTMLElement;
        table.innerHTML = "";
        const subEntries = await this.getSubentities();
        if (data.items) {
            for (const lineItem of data.items) {
                let attributes = {}
                lineItem.data.forEach((mapData: any) => {
                    if (mapData.payload) {
                        Object.keys(mapData.payload).forEach((key: string) => {
                            const v = mapData.payload[key]
                            if (v && v.length > 0) {
                                // @ts-ignore
                                if (!attributes[key]) {
                                    // @ts-ignore
                                    attributes[key] = []
                                }
                                // @ts-ignore
                                attributes[key].push(v)
                            }
                        })
                    }
                });
                let tr = `<tr>
                <!--<td><input type="checkbox" id="erp_order_line_item_table_${lineItem.id}" /> </td>!-->
                <td>${lineItem.data.length}</td>
                <td>${lineItem.product.name}</td>
                <td class="${!window.siteFeatures || !siteFeatures.wms ? 'd-none' : ''}">${Utils.translate(`erp.lineItem.status.${lineItem.wmsStatus}`)}</td>
                <td>${lineItem.data.map((d: { serial: any; }) => d.serial).filter(n => n).join(", ")}</td>`;

                subEntries.forEach((key: any) => {
                    const currentValue = attributes[key.name] ? attributes[key.name].filter(s => s).join(", ") : ''
                    tr += `<td>${currentValue}</td>`
                })
                tr += `<td><div class="d-flex align-items-center"> `
                if (lineItem.wmsStatus === "NEW" || !lineItem.wmsStatus) {
                    if (enableActions) {
                        tr += `<a href="#" data-ids="${lineItem.data.map((d: { id: any; }) => d.id).join(",")}" class="text-body delete-record"> <i class="ti ti-trash ti-sm mx-2"></i> </a>`
                    } else {
                        tr += `<a href="#" data-is-new="true" data-id="${lineItem.data.map((d: { id: any; }) => d.id).join(",")}" class="text-body delete-record"> <i class="ti ti-trash ti-sm mx-2"></i> </a>`

                    }
                }
                tr += `</div></td>
            </tr>`
                table.innerHTML = table.innerHTML + tr;
            }
        }

        this.createDataTable();
    }
}